import React, { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useOutsideClick } from "../../../hooks";
import { DropdownArrowIcon } from "../../../svg";
import { useRouter } from "next/router";
import { translate } from "../../../utils";

interface DropdownItems {
  name: string;
  url: string;
}
interface IProps {
  items: DropdownItems[];
}

export const Dropdown: FC<IProps> = ({ items }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale, pathname } = useRouter();
  const [selectedLocale, setLocale] = useState("en-US");
  const [selectedValue, setValue] = useState(
    translate(locale, "products_and_services")
  );

  const onToggleItems = () => {
    setIsOpen(!isOpen);
  };

  const onCloseDropdown = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(onCloseDropdown);

  useEffect(() => {
    if (locale !== selectedLocale) {
      setLocale(locale);
      setValue(translate(locale, "products_and_services"));
    }
  }, [locale, selectedLocale]);

  return (
    <div className="dropdown" ref={ref}>
      <div className="dropdown-header" onClick={onToggleItems}>
        <span>{selectedValue}</span>
        <DropdownArrowIcon />
      </div>
      {isOpen && (
        <div className="dropdown-body">
          {items.map((item) => (
            <div className="item" onClick={onCloseDropdown} key={item.name}>
              <Link href={item.url}>
                <a>{item.name}</a>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
