import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { ActionButton, Input, DropdownForm } from "../../index";
import { validationPaterns } from "../../../constants/validationPaterns";
import ReCAPTCHA from "react-google-recaptcha";
import { showDialog, translate } from "../../../utils";
import axios from "axios";
import { useRouter } from "next/router";
import Image from "next/image";
declare global {
  interface Window {
    recaptchaOptions: { lang?: string };
  }
}

type InputType = "text" | "tel" | "email";

interface IProps {
  pageName?: string;
}

interface IFormEl {
  name: string;
  type: string;
  label: string;
  required?: boolean;
  error?: boolean;
}

export const Contacts: FC<IProps> = memo(({ pageName }): JSX.Element => {
  const captchaRef = useRef(null);
  const { locale } = useRouter();

  const getInterestedDefaultValue = useMemo(
    () =>
      pageName === "portfolio-management"
        ? translate(locale, "portfolio_management_title")
        : pageName === "profile-rollout"
        ? translate(locale, "profile_rol_out_title")
        : translate(locale, "hfpc_title_products"),
    [pageName]
  );

  const [token, setToken] = useState("");
  const getInterestedItems = [
    translate(locale, "hfpc_title_products"),
    translate(locale, "portfolio_management_title"),
    translate(locale, "profile_rol_out_title"),
    translate(locale, "strategy_consulting_title"),
  ];

  const defaultState = {
    name: "",
    last_name: "",
    company: "",
    phone: "",
    email: "",
    interested_in: getInterestedDefaultValue,
    message: "",
  };
  const [contactsForm, setContactsForm] = useState(defaultState);

  const [inputs, setInputs] = useState<IFormEl[]>([
    {
      name: "name",
      type: "text",
      label: translate(locale, "contacts_name"),
      required: true,
    },
    {
      name: "last_name",
      type: "text",
      label: translate(locale, "contacts_last_name"),
      required: true,
    },
    {
      name: "company",
      type: "text",
      label: translate(locale, "contacts_company"),
    },
    {
      name: "phone",
      type: "tel",
      label: translate(locale, "contacts_phone"),
    },
    {
      name: "email",
      type: "email",
      label: translate(locale, "contacts_email"),
      required: true,
    },
  ]);

  useEffect(() => {
    setInputs((prevState) =>
      prevState.map((item) => ({
        ...item,
        label: translate(locale, `contacts_${item.name}`),
      }))
    );
  }, [locale]);

  const isFormValid = useMemo(() => {
    const areReqFieldsFill =
      contactsForm.name &&
      contactsForm.email &&
      contactsForm.message &&
      contactsForm.last_name;
    const isPhoneValid =
      (!!contactsForm.phone &&
        !!contactsForm.phone.match(validationPaterns.NUMBER_REGEX)) ||
      !contactsForm.phone;

    const isEmailValid =
      (!!contactsForm.email &&
        !!contactsForm.email.match(validationPaterns.EMAIL_REGEX)) ||
      !contactsForm.email;

    setInputs((prevState) => [
      ...prevState.map((input) => ({
        ...input,
        error:
          input.name === "phone"
            ? !isPhoneValid
            : input.name === "email"
            ? !isEmailValid
            : null,
      })),
    ]);

    return !!areReqFieldsFill && isEmailValid && isPhoneValid;
  }, [contactsForm]);

  const onResetForm = () => {
    setContactsForm(defaultState);
    captchaRef.current.reset();
  };

  const verifyToken = () => {
    axios
      .post("/api/v1/core/captcha/", {
        g_recaptcha_response: token,
      })
      .then((response) => response.status === 200 && onSendEmail())
      .catch(() =>
        showDialog({
          text: translate(locale, "captcha_error"),
          showCancelButton: false,
        })
      );
  };

  const onSendEmail = () => {
    axios
      .post("/api/v1/core/form/", contactsForm)
      .then((response) => response.status === 200 && onResetForm());
  };

  const onSubmitForm = (event) => {
    event.preventDefault();
    verifyToken();
  };

  const onChangeContactsForm = (event) => {
    setContactsForm((prevState) => ({
      ...prevState,
      [event.target.dataset.name]: event.target.value,
    }));
  };

  const onSelectDropdownItem = (item) => {
    setContactsForm((prevState) => ({
      ...prevState,
      interested_in: item,
    }));
  };

  const getToken = () => {
    const token = captchaRef.current.getValue();
    setToken(token);
  };

  return (
    <div className="contacts" id="contact">
      <div className="form-container">
        <h3>{translate(locale, "contacts_title")}</h3>
        <div className="form-wrapper">
          <div className="form-contact-information">
            <div className="contact-avatar">
              <Image
                src="/images/contacts-avatar.webp"
                alt="Contact person avatar"
                layout="fill"
                objectPosition="cover"
                priority={false}
              />
            </div>
            <p className="contact-ansprechpartner">
              {translate(locale, "contacts_person")}
            </p>
            <p className="contact-fullname">Rémi Bourion</p>
            <a
              href="mailto:info@rion-energie.ch"
              rel="noopener noreferrer"
              target="_blank"
              className="contact-email"
            >
              info@rion-energie.ch
            </a>
          </div>
          <form className="form-personal-information" onSubmit={onSubmitForm}>
            <div className="form-fields">
              {inputs.map(({ name, type, label, required, error }) => (
                <Input
                  key={name}
                  name={name}
                  type={type as InputType}
                  label={label}
                  onChange={onChangeContactsForm}
                  value={contactsForm[name]}
                  error={error}
                  required={required}
                />
              ))}
              <DropdownForm
                label={translate(locale, "contacts_interested_in")}
                items={getInterestedItems}
                defaultValue={contactsForm.interested_in}
                onChangeForm={onSelectDropdownItem}
              />
            </div>
            <textarea
              name="message"
              value={contactsForm.message}
              data-name="message"
              onChange={onChangeContactsForm}
              placeholder={translate(locale, "contacts_message")}
            />
            <div className="recaptcha-field">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={getToken}
                onExpired={getToken}
                hl={locale}
              />
            </div>
            <ActionButton
              title={translate(locale, "send_btn_text")}
              variant="primary"
              type="submit"
              isDisabled={!token || !isFormValid}
            />
          </form>
        </div>
      </div>
    </div>
  );
});
