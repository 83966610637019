import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { ActionButton, Dropdown, DropdownTranslation } from "../../index";
import { LogoIcon } from "../../../svg";
import { translate } from "../../../utils";
import cx from "classnames";

export const Header: FC = (): JSX.Element => {
  const { locale, pathname, push } = useRouter();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [height, setHeight] = useState<number>(100);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    isCollapsed ? setHeight(250) : setHeight(100);
  }, [isCollapsed]);

  const dropDownItems = useMemo(
    () => [
      {
        name: translate(locale, "hfpc_title_products"),
        url: `${locale}/hpfcelEctricity`,
      },
      {
        name: translate(locale, "portfolio_management_title"),
        url: `${locale}/portfolioManagement`,
      },
      {
        name: translate(locale, "profile_rol_out_title"),
        url: `${locale}/profileRollOut`,
      },
      {
        name: translate(locale, "strategy_consulting_title"),
        url: `/#offers_strategy-consulting`,
      },
    ],
    [locale]
  );

  const onNavigateToLogIn = () => {
    window.open("/app", "_blank");
  };

  const onNavigateToHome = () => {
    push("/");
  };

  const isPrivacyOrImprintPage = useMemo(
    () => pathname === "/privacy" || pathname === "/imprint",
    [pathname]
  );

  const initialPath = useMemo(
    () => (isPrivacyOrImprintPage ? "/" : ""),
    [isPrivacyOrImprintPage]
  );

  const aboutUs = useMemo(() => translate(locale, "about_us"), [locale]);
  const contacts = useMemo(() => translate(locale, "contact"), [locale]);

  const onToggleBurger = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const HeaderCX = cx("header", {
    collapsed: isCollapsed,
  });

  const renderMobileBodyCollapsed = () => (
    <div className="collapse-body">
      <div className="collapse-actions-container">
        <div className="nav-item">
          <Link href="/#aboutus">
            <a>{aboutUs}</a>
          </Link>
        </div>
        <div className="nav-item">
          <Link href={`${initialPath}#contact`}>
            <a>{contacts}</a>
          </Link>
        </div>
        <ActionButton
          title={translate(locale, "login_btn")}
          variant="secondary"
          onClick={onNavigateToLogIn}
        />
        <DropdownTranslation />
      </div>
      <Dropdown items={dropDownItems} />
    </div>
  );

  return (
    <div className={HeaderCX} style={{ height }} ref={ref}>
      <div className="header-wrapper">
        <div className="logo-wrapper" onClick={onNavigateToHome}>
          <LogoIcon />
        </div>
        <div className="actions-container">
          <Dropdown items={dropDownItems} />
          <div className="nav-item">
            <Link href="/#aboutus">
              <a>{aboutUs}</a>
            </Link>
          </div>
          <div className="nav-item">
            <Link href={`${initialPath}#contact`}>
              <a>{contacts}</a>
            </Link>
          </div>
          <ActionButton
            title={translate(locale, "login_btn")}
            variant="secondary"
            onClick={onNavigateToLogIn}
          />
        </div>
        <DropdownTranslation />
        <div className="burger" onClick={onToggleBurger}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      {renderMobileBodyCollapsed()}
    </div>
  );
};
