import React, { FC } from "react";
import { Carousel } from "react-responsive-carousel";
import Image from "next/image";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CarouselItems } from "../../modules/AboutApp/AboutApp";
interface IProps {
  items: CarouselItems[];
}

export const CarouselComponent: FC<IProps> = ({ items }): JSX.Element => {
  return (
    <div className="carousel">
      <Carousel
        autoPlay
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        interval={5000}
        infiniteLoop
      >
        {items.map((item) => (
          <Image
            key={item.src}
            src={`/images/${item.src}.webp`}
            width={item.width}
            height={item.height}
            layout="fill"
            className="carousel-item-image"
          />
        ))}
      </Carousel>
    </div>
  );
};
