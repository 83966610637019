import React, { FC } from "react";
import { RootLayout } from "../components/layouts/RootLayout/RootLayout";

export const WithRootLayout = <T extends Record<string, unknown>>(
  Component: FC<T>
) => {
  return function WithRootLayoutComponent(props: T): JSX.Element {
    return (
      <RootLayout>
        <Component {...props} />
      </RootLayout>
    );
  };
};
