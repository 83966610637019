import React, { FC } from "react";

interface IProps {
  title: string;
  description: string;
  isHomePage?: boolean;
}

export const AboutCompany: FC<IProps> = ({
  title,
  description,
  isHomePage,
}) => {
  return (
    <div className="about-company" id="aboutus" data-home-page={isHomePage}>
      <h2>{title}</h2>
      <div className="hr"></div>
      <p>{description}</p>
    </div>
  );
};
