import React, { FC } from "react";

export const FlashOnIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="42"
      height="80"
      viewBox="0 0 42 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.9375 0.0625H41.0625L24.9375 31.9375H41.0625L12.9375 79.9375V43.9375H0.9375V0.0625Z"
        fill="white"
      />
    </svg>
  );
};
