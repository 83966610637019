import React, { FC } from "react";
import { SuccessIcon } from "../../../svg";
import { motion } from "framer-motion";

interface IProps {
  items: string[];
}

const animationVariants = {
  hidden: {
    x: -200,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { delay: 0.3 },
  },
};

export const Benefits: FC<IProps> = ({ items }): JSX.Element => {
  return (
    <motion.div
      variants={animationVariants}
      initial="hidden"
      whileInView="visible"
      className="benefits"
    >
      {items.map((item) => (
        <motion.div
          className="item"
          key={item}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.4 }}
        >
          <SuccessIcon />
          <p>{item}</p>
        </motion.div>
      ))}
    </motion.div>
  );
};
