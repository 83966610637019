import React, { FC, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useOutsideClick } from "../../../hooks";
import { DropdownArrowIcon } from "../../../svg";

export const DropdownTranslation: FC = (): JSX.Element => {
  const router = useRouter();
  const currentLang =
    router.locale.charAt(0).toUpperCase() + router.locale.slice(1);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(currentLang);

  const languages = ["En", "De", "Fr"];

  const onToggleItems = () => {
    setIsOpen(!isOpen);
  };

  const onCloseDropdown = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(onCloseDropdown);

  const onChangeLang = useCallback(
    (lang: string) => () => {
      router.push(router.pathname, router.pathname, {
        locale: lang.toLowerCase(),
      });
      setSelectedLang(lang);
      onCloseDropdown();
    },
    []
  );

  return (
    <div className="dropdown-translation" ref={ref}>
      <div className="dropdown-translation-header" onClick={onToggleItems}>
        <span>{selectedLang}</span>
        <DropdownArrowIcon />
      </div>
      {isOpen && (
        <div className="dropdown-translation-body">
          {languages.map((lang) => (
            <div className="item" onClick={onChangeLang(lang)} key={lang}>
              <span>{lang}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
