import React, { FC } from "react";
import Image from "next/image";
import { ActionButton, CarouselComponent } from "../../index";
import { translate } from "../../../utils";
import { useRouter } from "next/router";

interface CarouselContent {
  title: string;
  description: string;
}

export interface CarouselItems {
  src: string;
  width: number;
  height: number;
  content?: CarouselContent;
}

interface IProps {
  imageName?: string;
  description: string;
  carouselItems?: CarouselItems[];
}

export const AboutApp: FC<IProps> = ({
  imageName,
  description,
  carouselItems,
}): JSX.Element => {
  const { push, locale } = useRouter();
  const onNavigateToContanct = () => {
    push("#contact");
  };
  return (
    <div className="about-app" id="offers">
      <div className="image-container">
        {carouselItems ? (
          <CarouselComponent items={carouselItems} />
        ) : (
          <Image
            src={`/images/${imageName}.webp`}
            width={540}
            height={338}
            layout="fill"
          />
        )}
      </div>
      <div className="description-container">
        <p>{description}</p>
        <div className="btn-container">
          <ActionButton
            title={translate(locale, "contact_us_btn")}
            variant="primary"
            onClick={onNavigateToContanct}
          />
        </div>
      </div>
    </div>
  );
};
