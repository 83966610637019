import React, { FC } from "react";

export const DropdownArrowIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75648 0.21959C9.61584 0.0789867 9.42511 -2.40835e-08 9.22623 -3.27765e-08C9.02736 -4.14695e-08 8.83663 0.0789866 8.69599 0.21959L4.98349 3.93209L1.27099 0.21959C1.12954 0.082971 0.940086 0.0073749 0.743439 0.0090837C0.546791 0.0107925 0.358682 0.0896694 0.219626 0.228726C0.0805702 0.367782 0.0016937 0.555891 -1.4338e-05 0.752538C-0.00172333 0.949186 0.0738725 1.13864 0.210491 1.28009L4.45324 5.52284C4.59388 5.66344 4.78461 5.74243 4.98349 5.74243C5.18236 5.74243 5.37309 5.66344 5.51374 5.52284L9.75648 1.28009C9.89709 1.13944 9.97607 0.948713 9.97607 0.74984C9.97607 0.550967 9.89709 0.360236 9.75648 0.21959Z"
        fill="#6E7991"
      />
    </svg>
  );
};
