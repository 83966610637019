import React, { ChangeEvent, FC } from "react";
import cx from "classnames";

interface IProps {
  type: "text" | "tel" | "email";
  name: string;
  label: string;
  value: string;
  error?: boolean;
  required?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Input: FC<IProps> = ({
  type,
  name,
  label,
  value,
  error,
  required,
  onChange,
}): JSX.Element => {
  const InputCX = cx("input", {
    "input-error": !!error,
  });

  return (
    <div className={InputCX}>
      <label htmlFor={name}>
        {label}
        {!!required && "*"}
      </label>
      <input
        id={name}
        type={type}
        value={value}
        placeholder={label}
        data-name={name}
        onChange={onChange}
      />
    </div>
  );
};
