import React, { FC } from "react";

export const SuccessIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0002 60.0018C46.569 60.0018 60.0005 46.5701 60.0005 30.0009H53.3338C53.3338 42.888 42.887 53.3349 30.0002 53.3349C17.1135 53.3349 6.66672 42.888 6.66672 30.0009C6.66672 17.1139 17.1135 6.66687 30.0002 6.66687C33.7063 6.66687 37.2106 7.53093 40.3227 9.06857L44.769 3.88112C40.409 1.41048 35.3693 0 30.0002 0C13.4316 0 0 13.4319 0 30.0009C0 46.5701 13.4316 60.0018 30.0002 60.0018ZM52.4793 18.8974C53.7109 17.529 53.5999 15.4213 52.2316 14.1897C50.8632 12.9582 48.7556 13.0691 47.5239 14.4375L28.8819 35.1513L22.366 28.593C21.0685 27.287 18.9579 27.2802 17.6519 28.5777C16.346 29.8753 16.3391 31.9859 17.6366 33.2919L26.6369 42.3509C27.2827 43.0009 28.1677 43.3566 29.0838 43.3339C29.9998 43.3112 30.8662 42.9126 31.4792 42.2315L52.4793 18.8974Z"
        fill="#8BBA05"
      />
    </svg>
  );
};
