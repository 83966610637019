import React, { FC, useState, useRef, useEffect } from "react";
import { ArrowUpIcon, ArrowDownIcon } from "../../../svg";
import cx from "classnames";

interface IProps {
  title: string;
  mainContent?: JSX.Element;
  additionalContent?: JSX.Element;
  isOpenByDefault: boolean;
}

export const Collapse: FC<IProps> = ({
  title,
  mainContent,
  additionalContent,
  isOpenByDefault,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [height, setHeight] = useState<number>();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const CollapseCX = cx("collapse", {
    collapsed: isOpen,
  });

  return (
    <div className={CollapseCX}>
      <div className="collapse-header">
        <div className="header-wrapper" onClick={toggleCollapse}>
          <h3>{title}</h3>
          <div className="icon">
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
      </div>
      <div className="collapse-body" style={{ height }}>
        <div className="body-wrapper" ref={ref}>
          <div className="main-content">{mainContent}</div>
          <div className="additional-content">{additionalContent}</div>
        </div>
      </div>
    </div>
  );
};
