import React, { FC } from "react";

export const ArrowDownIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6478 0.344159C15.4222 0.123794 15.1163 1.05375e-08 14.7974 1.4341e-08C14.4784 1.81446e-08 14.1725 0.123794 13.947 0.344159L7.99274 6.16269L2.03852 0.344159C1.81166 0.13004 1.50781 0.0115593 1.19242 0.0142375C0.877029 0.0169157 0.575334 0.140538 0.352312 0.358478C0.12929 0.576418 0.00278569 0.871238 4.57904e-05 1.17944C-0.00269507 1.48764 0.118547 1.78457 0.33766 2.00626L7.14231 8.65584C7.36788 8.87621 7.67378 9 7.99274 9C8.3117 9 8.6176 8.87621 8.84317 8.65584L15.6478 2.00626C15.8733 1.78583 16 1.4869 16 1.17521C16 0.863519 15.8733 0.564591 15.6478 0.344159Z"
        fill="#4A4A4A"
      />
    </svg>
  );
};
