import React, { FC } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { translate } from "../../../utils";
import Image from "next/image";

export const Footer: FC = (): JSX.Element => {
  const { locale } = useRouter();

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="company-name-container">
          <span>2023 | RION Energie GmbH</span>
        </div>
        <Link
          href="https://www.linkedin.com/company/rion-energie"
          className="linkedin-link"
        >
          <Image
            src="/images/linkedin.svg"
            objectFit="fill"
            loading="eager"
            alt="linkedin"
            className="linkedin"
            quality={50}
            layout="fill"
            priority={false}
          />
        </Link>
        <div className="additional-information-container">
          <div>
            <Link href="/privacy">
              <a>{translate(locale, "privacy_title")}</a>
            </Link>
          </div>
          <div>
            <Link href="/imprint">
              <a>{translate(locale, "imprint_title")}</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
