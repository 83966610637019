import React, { FC, useMemo } from "react";
import { useRouter } from "next/router";
import { ActionButton } from "../../index";
import { translate } from "../../../utils";

interface IProps {
  title: string;
  subTitle?: string;
  description: string;
  pageName: string;
  withImage?: boolean;
  isGenegalPage?: boolean;
}

export const Intro: FC<IProps> = ({
  title,
  subTitle,
  description,
  pageName,
  withImage,
  isGenegalPage,
}): JSX.Element => {
  const { push, locale } = useRouter();
  const navigateToOffers = () => {
    push("#offers");
  };

  const actionBtnTitle = useMemo(
    () =>
      isGenegalPage
        ? translate(locale, "offer_btn")
        : translate(locale, "what_we_offer_btn"),
    [isGenegalPage, locale]
  );

  const renderIntroContent = () => (
    <div className="intro-content" data-page-name={pageName}>
      <div className="description">
        <h1>
          {title}
          <br />
          {subTitle}
        </h1>
        <h2>{description}</h2>
      </div>
      <div className="btn-container">
        <ActionButton
          title={actionBtnTitle}
          variant="primary"
          onClick={navigateToOffers}
        />
      </div>
    </div>
  );

  return (
    <div
      className="intro"
      style={
        withImage && {
          backgroundImage: `url(/images/${pageName}_intro.webp)`,
          backgroundPosition: "right top",
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: /*"#020b0ff2"*/ "#03151f",
        }
      }
    >
      {!withImage && (
        <video preload="auto" playsInline autoPlay muted loop>
          <source src={`/videos/${pageName}-intro.mp4`} type="video/mp4" />
        </video>
      )}
      {renderIntroContent()}
    </div>
  );
};
