import React, { FC } from "react";
import { FlashOnIcon } from "../../../svg";

interface IProps {
  title: string;
  description: string;
}

export const GetInterested: FC<IProps> = ({
  title,
  description,
}): JSX.Element => {
  return (
    <div className="get-interested">
      <div className="icon-container">
        <FlashOnIcon />
      </div>
      <div>
        <h3>{title}</h3>
        <span>{description}</span>
      </div>
    </div>
  );
};
