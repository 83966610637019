import React, { FC } from "react";
import cx from "classnames";

interface IProps {
  title: string;
  variant: string;
  isDisabled?: boolean;
  type?: "submit" | "button";
  onClick?: () => void;
}

enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export const ActionButton: FC<IProps> = ({
  title,
  type,
  isDisabled,
  variant,
  onClick,
}): JSX.Element => {
  const ButtonCX = cx("btn", {
    "btn-primary": variant === ButtonVariant.PRIMARY,
    "btn-secondary": variant === ButtonVariant.SECONDARY,
  });

  return (
    <button
      disabled={isDisabled}
      className={ButtonCX}
      onClick={onClick}
      type={type}
    >
      {title}
    </button>
  );
};
