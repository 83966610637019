import React, { FC, useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { useProgressiveImage } from "../../../hooks";
import { useRouter } from "next/router";
import { translate } from "../../../utils";
import { ActionButton } from "../../elements/ActionButton/ActionButton";

export const ProductsServices: FC = (): JSX.Element => {
  const { push, locale, asPath } = useRouter();

  const isStrategyConsultingSelected =
    asPath === "/#offers_strategy-consulting";

  const activeTab = {
    mainPageId: "offers",
    strategyConsultingId: "offers_strategy-consulting",
  };

  const [content, setContent] = useState([
    {
      name: "hpfc_product",
      title: "",
      subTitle: "",
      description: "",
      tabTitle: "",
      tabSubTitle: "",
      src: "/images/electricity-theme.webp",
      url: "/hpfcelEctricity",
      active: true,
    },
    {
      name: "portfolio_product",
      title: "",
      subTitle: "",
      description: "",
      tabTitle: "",
      tabSubTitle: "",
      src: "/images/portfolio-management_intro.webp",
      url: "/portfolioManagement",
      active: false,
    },
    {
      name: "profile_rol_out_product",
      title: "",
      description: "",
      tabTitle: "",
      tabSubTitle: "",
      src: "/images/profile-rollout_intro.webp",
      url: "/profileRollOut",
      active: false,
    },
    {
      name: "strategy_consulting",
      title: "",
      description: "",
      tabTitle: "",
      tabSubTitle: "",
      src: "/images/strategy_consulting_intro.webp",
      active: false,
    },
  ]);

  const activeContent = content.find((item) => item.active);
  const loadedImageSrc = useProgressiveImage(activeContent.src);

  useEffect(() => {
    setContent((prevState) =>
      prevState.map((item) => ({
        ...item,
        title: translate(locale, `${item.name}_title`),
        subTitle:
          item.name !== "profile_rol_out_product" &&
          item.name !== "strategy_consulting" &&
          translate(locale, `${item.name}_subtitle`),
        description: translate(locale, `${item.name}_description`),
        tabTitle: translate(locale, `${item.name}_title`),
        tabSubTitle:
          item.name !== "profile_rol_out_product" &&
          item.name !== "strategy_consulting" &&
          translate(locale, `${item.name}_subtitle`),
      }))
    );
  }, [locale]);

  const handleContent = (name: string) =>
    setContent((prevState) =>
      prevState.map((item) => ({ ...item, active: item.name === name }))
    );

  useEffect(() => {
    isStrategyConsultingSelected && handleContent("strategy_consulting");
  }, [asPath]);

  const toggleActiveContent = (event) => {
    const clickedItemName = event.currentTarget.dataset.name;
    if (activeContent.name === clickedItemName) return;
    handleContent(clickedItemName);
  };

  const ItemWrapperCX = useCallback(
    (active: boolean) =>
      cx("item-wrapper", {
        active,
      }),
    []
  );

  const navigateToSelectedPage = () => {
    push(activeContent.url);
  };

  return (
    <div className="products-services">
      <div
        className="heading"
        id={
          isStrategyConsultingSelected
            ? activeTab.strategyConsultingId
            : activeTab.mainPageId
        }
      >
        <h2>{translate(locale, "products_and_services")}</h2>
      </div>
      <div
        className="content-container"
        style={{
          backgroundImage: `url(${loadedImageSrc})`,
          backgroundPosition: "center top",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="content-wrapper">
          <div className="content">
            <div className="content-title">
              <h2>
                {activeContent.title}
                <br />
                {activeContent.subTitle}
              </h2>
              <p>{activeContent.description}</p>
              {activeContent.url && (
                <ActionButton
                  title={translate(locale, "learn_more_btn")}
                  variant="primary"
                  onClick={navigateToSelectedPage}
                />
              )}
            </div>
            <div className="content-description">
              {content.map((item) => (
                <div key={item.name} className={ItemWrapperCX(item.active)}>
                  <div
                    className="item"
                    onClick={toggleActiveContent}
                    data-name={item.name}
                  >
                    <h3>
                      {item.tabTitle}
                      <br />
                      {item.tabSubTitle}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
