import React, { FC, useCallback, useEffect, useState } from "react";
import { useOutsideClick } from "../../../hooks";
import { DropdownArrowIcon } from "../../../svg";

interface IProps {
  label?: string;
  items: string[];
  defaultValue: string;
  onChangeForm: (item: string) => void;
}

export const DropdownForm: FC<IProps> = ({
  label,
  items,
  defaultValue,
  onChangeForm,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    if (selectedValue !== defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const onToggleItems = () => {
    setIsOpen((prev) => !prev);
  };

  const onCloseDropdown = () => {
    setIsOpen(false);
  };

  const onChangeValue = useCallback(
    (item: string) => () => {
      setSelectedValue(item);
      onChangeForm(item);
      onCloseDropdown();
    },
    []
  );

  const ref = useOutsideClick(onCloseDropdown);

  return (
    <div className="dropdown-form" ref={ref}>
      {label && <label title={label}>{label}</label>}
      <div className="dropdown-form-header" onClick={onToggleItems}>
        <span>{selectedValue}</span>
        <DropdownArrowIcon />
      </div>
      {isOpen && (
        <div className="dropdown-form-body">
          {items.map((item) => (
            <div className="item" key={item} onClick={onChangeValue(item)}>
              <span>{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
