import React, { FC, useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Image from "next/image";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useRouter } from "next/router";
import { translate } from "../../../utils";
import cx from "classnames";

export const Charts: FC = (): JSX.Element => {
  const { locale } = useRouter();
  const carouselItems = [
    {
      src: "consumer-chart",
      content: {
        title: translate(locale, "chart_performance_consumer_title"),
        description: translate(
          locale,
          "chart_performance_consumer_description"
        ),
      },
    },
    {
      src: "producer-chart",
      content: {
        title: translate(locale, "chart_performance_producer_title"),
        description: translate(
          locale,
          "chart_performance_producer_description"
        ),
      },
    },
  ];

  const [chartDataItems] = useState({
    items: [
      {
        year: 2023,
        buyAver: 8.934,
        markt: 10.614,
        sellAver: 11.81 + "0",
      },
      {
        year: 2022,
        buyAver: 5.618,
        markt: 5.722,
        sellAver: 5.638,
      },
      {
        year: 2021,
        buyAver: 4.585,
        markt: 4.662,
        sellAver: 4.691,
      },
      {
        year: 2020,
        buyAver: 4.402,
        markt: 4.423,
        sellAver: 4.712,
      },
      {
        year: 2019,
        buyAver: 3.533,
        markt: 3.779,
        sellAver: 3.839,
      },
      {
        year: 2018,
        buyAver: 3.245,
        markt: 3.451,
        sellAver: 3.583,
      },
      {
        year: 2017,
        buyAver: 3.416,
        markt: 3.63 + "0",
        sellAver: 3.698,
      },
      {
        year: 2016,
        buyAver: 4.069,
        markt: 4.147,
        sellAver: 4.166,
      },
      {
        year: 2015,
        buyAver: 4.629,
        markt: 4.957,
        sellAver: 5.056,
      },
      {
        year: 2014,
        buyAver: 3.245,
        markt: 3.451,
        sellAver: 3.583,
      },
    ],
  });

  const [page, setPage] = useState(0);

  const onChangePage = (page) => {
    setPage(page);
  };

  const pageName = useMemo(
    () => (page === 1 ? "producer-page" : "consumer-page"),
    [page]
  );

  const SellCx = cx("sell", {
    active: pageName === "producer-page",
  });

  const BuyCX = cx("buy", {
    active: pageName === "consumer-page",
  });

  return (
    <div className="charts">
      <div className="carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          autoPlay
          interval={10000}
          infiniteLoop
          onChange={onChangePage}
        >
          {carouselItems.map((item) => (
            <div className="content-wrapper" key={item.src}>
              <Image
                src={`/images/${item.src}.webp`}
                width={350}
                height={245}
                layout="fill"
              />
              <div className="description">
                <h2>{item.content.title}</h2>
                <span className="carousel-desription-item">{item.content.description}</span>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <p className="performance-content">
        {translate(locale, "performance_subtitle")}
      </p>
      <div className="data-container">
        {chartDataItems.items.map((item, index) => (
          <div key={item.year} className="item" data-index={index}>
            <div className="item-head">
              <h4>{item.year}</h4>
              {index === 0 && <p>{translate(locale, "chart_title")}</p>}
            </div>
            <div className="item-information">
              <div className="column-name">
                <p>{translate(locale, "buy_average")}</p>
                <p>{translate(locale, "market")}</p>
                <p>{translate(locale, "sell_average")}</p>
              </div>
              <div className="column-data" data-page={pageName}>
                <p className={BuyCX}>{item.buyAver}</p>
                <p>{item.markt}</p>
                <p className={SellCx}>{item.sellAver}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
