import React, { FC } from "react";

export const ArrowUpIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.352185 8.65584C0.577756 8.87621 0.883657 9 1.20262 9C1.52157 9 1.82747 8.87621 2.05305 8.65584L8.00726 2.83731L13.9615 8.65584C14.1883 8.86996 14.4922 8.98844 14.8076 8.98576C15.123 8.98308 15.4247 8.85946 15.6477 8.64152C15.8707 8.42358 15.9972 8.12876 16 7.82056C16.0027 7.51236 15.8815 7.21543 15.6623 6.99374L8.85769 0.344159C8.63212 0.123795 8.32622 9.92892e-08 8.00726 9.54856e-08C7.6883 9.16821e-08 7.3824 0.123795 7.15683 0.344159L0.352185 6.99374C0.126681 7.21417 1.77311e-08 7.5131 1.40142e-08 7.82479C1.02974e-08 8.13648 0.126681 8.43541 0.352185 8.65584Z"
        fill="#8BBA05"
      />
    </svg>
  );
};
